import { useState, useEffect } from "react";
import { Dialog, DialogContent } from "@/components/ui/dialog";
import { Textarea } from "@/components/ui/textarea";
import { Button } from "@/components/ui/button";
import { useCreateCompanyDocsV2Chat } from "../../new-chat/api/useCreateCompanyDocsV2Chat";
import { useCreatePlaybookChat } from "../../playbook/api/useCreatePlaybookChat";
import { useNavigate } from "react-router-dom";
import { Loader2, Wand2 } from "lucide-react";
import { PromptImproverModal } from "../../existing-chat/components/PromptImproverModal";
import ChatContext from "@/shared/models/ChatContext";

interface ResearchQuestionModalProps {
  open: boolean;
  onClose: () => void;
  context: ChatContext;
  researchType?: "chat" | "playbook";
  title?: string;
  playbookId?: string;
  isDefaultPlaybook?: boolean;
}

export function ResearchQuestionModal({
  open,
  onClose,
  context,
  researchType,
  title,
  playbookId,
  isDefaultPlaybook = false,
}: ResearchQuestionModalProps) {
  const [question, setQuestion] = useState(title || "");
  const [showPromptImprover, setShowPromptImprover] = useState(false);
  const { createChat: createCompanyChat, isLoading: isCompanyChatLoading } =
    useCreateCompanyDocsV2Chat();
  const { createChat: createPlaybookChat, chat: playbookChat } =
    useCreatePlaybookChat();
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!question.trim() || !researchType || isCompanyChatLoading) return;

    if (researchType === "chat") {
      const response = await createCompanyChat(question, context);

      if (response?.id) {
        console.log("Navigating to chat:", response.id);
        onClose(); // Close modal first to prevent state issues
        navigate(`/c/${response.id}`);
        return; // Return early to prevent the second onClose call
      }
    } else if (researchType === "playbook" && playbookId) {
      await createPlaybookChat(
        playbookId,
        isDefaultPlaybook,
        question, // Use question as playbook title
        false, // sendPdf,
        context,
      );

      if (playbookChat?.id) {
        onClose();
        navigate(`/c/${playbookChat.id}`);
        return;
      }
    }
  };

  useEffect(() => {
    if (title && researchType === "playbook") {
      handleSubmit(new Event("submit") as any);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const isLoading = isCompanyChatLoading;

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[800px]">
        <div className="p-6">
          <h2 className="text-2xl font-semibold text-gray-900 mb-6">
            {researchType === "chat"
              ? "Enter Research Question"
              : "Enter Playbook Title"}
          </h2>
          <form onSubmit={handleSubmit}>
            <div className="space-y-4">
              {(!title || researchType !== "playbook") && (
                <div className="flex gap-2">
                  <Textarea
                    placeholder={
                      researchType === "chat"
                        ? "What would you like to research?"
                        : "Enter a title for your research playbook"
                    }
                    value={question}
                    onChange={(e) => setQuestion(e.target.value)}
                    className="flex-1 min-h-[120px] resize-none"
                    autoFocus
                    disabled={isLoading}
                  />
                  <Button
                    type="button"
                    variant="outline"
                    onClick={() =>
                      question.trim() && setShowPromptImprover(true)
                    }
                    className="gap-2 px-3 hover:bg-gray-50 border-gray-200 h-fit"
                    disabled={!question.trim() || isLoading}
                  >
                    <Wand2 className="h-4 w-4 text-gray-600" />
                    <span className="text-sm text-gray-600">Improve</span>
                  </Button>
                </div>
              )}
              <div className="flex justify-end gap-3">
                <Button
                  type="button"
                  variant="outline"
                  onClick={onClose}
                  disabled={isLoading}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  disabled={!question.trim() || isLoading || !researchType}
                  className="bg-gray-900 hover:bg-gray-800"
                >
                  {isLoading ? (
                    <Loader2 className="h-4 w-4 animate-spin" />
                  ) : researchType === "chat" ? (
                    "Start Research"
                  ) : (
                    "Create Playbook"
                  )}
                </Button>
              </div>
            </div>
          </form>
        </div>
      </DialogContent>

      <PromptImproverModal
        isOpen={showPromptImprover}
        onClose={() => setShowPromptImprover(false)}
        originalPrompt={question}
        onAccept={(improvedPrompt) => {
          setQuestion(improvedPrompt);
          setShowPromptImprover(false);
        }}
      />
    </Dialog>
  );
}
