import { useApiPrivateRequest } from "../../../shared/hooks/useApi";
import { ChatShort } from "../../../shared/models/Chat";

export const useSendUserInputV2 = (chatId: string) => {
  const { call, isLoading: isUserInputLoading } =
    useApiPrivateRequest<ChatShort>((response) => new ChatShort(response));
  const sendUserInputV2 = async (
    input: string,
    companyIds?: number[],
    filingIds?: string[],
    transcriptIds?: number[],
    files?: File[],
    urls?: string[],
  ) => {
    const formatData = new FormData();
    formatData.append("Input", input.trim());
    if (companyIds && companyIds.length > 0) {
      for (let i = 0; i < companyIds.length; i++) {
        formatData.append(`CompanyIds`, companyIds[i].toString());
      }
    }
    if (filingIds && filingIds.length > 0) {
      for (let i = 0; i < filingIds.length; i++) {
        formatData.append(`FilingIds`, filingIds[i]);
      }
    }
    if (transcriptIds && transcriptIds.length > 0) {
      for (let i = 0; i < transcriptIds.length; i++) {
        formatData.append(`TranscriptIds`, transcriptIds[i].toString());
      }
    }
    if (files && files.length > 0) {
      files.forEach((file) => formatData.append("Files", file));
    }
    if (urls && urls.length > 0) {
      urls.forEach((url) => formatData.append("Urls", url));
    }
    await call(`/chat/${chatId}/v2`, "POST", undefined, formatData);
  };

  return { isUserInputLoading, sendUserInputV2 };
};
