import { useState } from "react";
import { Button } from "@/components/ui/button";
import { Card, CardContent } from "@/components/ui/card";
import {
  Building2,
  ChevronRight,
  FileText,
  Globe,
  Newspaper,
  Plus,
} from "lucide-react";
import { ChatUserContent } from "@/features/existing-chat/models/ChatUserContent";
import { ResolvedMetadata } from "@/shared/models/ResolvedMetadata";

interface ResearchContextProps {
  context: {
    companyIds: string;
    filingIds: string;
    transcriptIds: string;
    uploadedFiles: ChatUserContent[];
    useNews: boolean;
    useInternet: boolean;
    resolvedMetadata: ResolvedMetadata | null | undefined;
  };
  onAddContext: () => void;
}

export function ResearchContext({
  context,
  onAddContext,
}: ResearchContextProps) {
  const [isContextExpanded, setIsContextExpanded] = useState(false);

  return (
    <Card className="mb-4 border-gray-200">
      <div className="flex items-center justify-between p-4">
        <button
          onClick={() => setIsContextExpanded(!isContextExpanded)}
          className="flex-1 flex items-center justify-between hover:bg-gray-50/50 rounded-md p-2"
        >
          <div className="flex items-center gap-2">
            <div className="bg-gray-100 p-1.5 rounded-md">
              <FileText className="h-4 w-4 text-gray-700" />
            </div>
            <div className="flex items-center gap-2">
              <h2 className="text-base font-semibold text-gray-900">
                Research Context
              </h2>
              <div className="flex items-center gap-2 text-sm text-gray-500">
                <span>•</span>
                {(() => {
                  const companyCount =
                    context.resolvedMetadata?.companies?.length || 0;
                  return (
                    <span>
                      {companyCount}{" "}
                      {companyCount === 1 ? "Company" : "Companies"}
                    </span>
                  );
                })()}
                <span>•</span>
                {(() => {
                  const documentCount =
                    (context.resolvedMetadata?.documents?.length || 0) +
                    (context.uploadedFiles?.length || 0);
                  return (
                    <span>
                      {documentCount}{" "}
                      {documentCount === 1 ? "Document" : "Documents"}
                    </span>
                  );
                })()}
              </div>
            </div>
          </div>
          <ChevronRight
            className={`h-5 w-5 text-gray-400 transition-transform duration-200
                                      ${isContextExpanded ? "rotate-90" : ""}`}
          />
        </button>

        <Button
          variant="outline"
          size="sm"
          onClick={onAddContext}
          className="ml-4 gap-2"
        >
          <Plus className="h-4 w-4" />
          Add Context
        </Button>
      </div>

      {isContextExpanded && (
        <CardContent className="border-t pt-4">
          <div className="grid grid-cols-2 gap-3">
            {/* Companies */}
            <div className="space-y-1.5">
              <h3 className="text-xs font-medium text-gray-700">Companies</h3>
              <div className="flex flex-wrap gap-1.5">
                {context.resolvedMetadata?.companies?.length ? (
                  context.resolvedMetadata.companies.map((company) => (
                    <span
                      key={company.id}
                      className="inline-flex items-center px-2 py-0.5 rounded-full 
                                     text-xs bg-gray-100 text-gray-700 border border-gray-200"
                    >
                      <Building2 className="h-3 w-3 mr-1" />
                      {company.companyName}
                    </span>
                  ))
                ) : (
                  <span className="text-xs text-gray-500">None specified</span>
                )}
              </div>
            </div>

            {/* Documents */}
            <div className="space-y-1.5">
              <h3 className="text-xs font-medium text-gray-700">Documents</h3>
              <div className="flex flex-wrap gap-1.5">
                {(context.resolvedMetadata?.documents?.length ?? 0) > 0 ||
                (context.uploadedFiles?.length ?? 0) > 0 ? (
                  <>
                    {context.resolvedMetadata?.documents?.map((doc) => (
                      <span
                        key={doc.id}
                        className="inline-flex items-center px-2 py-0.5 rounded-full 
                                       text-xs bg-gray-100 text-gray-700 border border-gray-200"
                      >
                        <FileText className="h-3 w-3 mr-1" />
                        {doc.title}
                      </span>
                    ))}
                    {context.uploadedFiles?.map((file) => (
                      <span
                        key={file.title}
                        className="inline-flex items-center px-2 py-0.5 rounded-full 
                                       text-xs bg-purple-50 text-purple-700 border border-purple-200"
                      >
                        <FileText className="h-3 w-3 mr-1" />
                        {file.title}
                      </span>
                    ))}
                  </>
                ) : (
                  <span className="text-xs text-gray-500">
                    No documents selected
                  </span>
                )}
              </div>
            </div>

            {/* Additional Context */}
            <div className="col-span-2 flex gap-3 mt-1.5">
              <div className="flex items-center gap-1.5">
                <div
                  className={`p-1 rounded-md ${
                    context.useNews
                      ? "bg-gray-900 text-white"
                      : "bg-gray-100 text-gray-700"
                  }`}
                >
                  <Newspaper className="h-3.5 w-3.5" />
                </div>
                <span className="text-xs text-gray-700">
                  News:{" "}
                  <span className="font-medium">
                    {context.useNews ? "Yes" : "No"}
                  </span>
                </span>
              </div>

              <div className="flex items-center gap-1.5">
                <div
                  className={`p-1 rounded-md ${
                    context.useInternet
                      ? "bg-gray-900 text-white"
                      : "bg-gray-100 text-gray-700"
                  }`}
                >
                  <Globe className="h-3.5 w-3.5" />
                </div>
                <span className="text-xs text-gray-700">
                  Web Access:{" "}
                  <span className="font-medium">
                    {context.useInternet ? "Yes" : "No"}
                  </span>
                </span>
              </div>
            </div>
          </div>
        </CardContent>
      )}
    </Card>
  );
}
