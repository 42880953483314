import { useParams, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useGetChat } from "../features/existing-chat/api/useGetChat";
import { useCreateReport } from "../features/existing-chat/api/useCreateReport";
import { useExportChatReport } from "../features/existing-chat/api/useExportChatReport";
import { ChatReport } from "../shared/models/ChatReport";
import { Button } from "@/components/ui/button";
import { MarkdownWithCitations } from "../features/sources/MarkdownWithCitations";
import markdownClasses from "../shared/styles/Markdown.module.css";
import { ScrollArea } from "@/components/ui/scroll-area";
import { FileDown, ArrowLeft, RefreshCw } from "lucide-react";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { cn } from "@/lib/utils";

export default function Report() {
  const { chatId } = useParams();
  const navigate = useNavigate();
  const { chat, getChat } = useGetChat(chatId || "");
  const { newReport, createReport } = useCreateReport(chatId || "");
  const { isExporting, exportChatReport } = useExportChatReport();
  const [report, setReport] = useState<ChatReport | undefined>(undefined);
  const [chatTitle, setChatTitle] = useState<string>("Loading...");
  const [isCreatingReport, setIsCreatingReport] = useState(false);

  useEffect(() => {
    if (chatId) {
      getChat();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatId]);

  useEffect(() => {
    if (chat?.input) {
      setChatTitle(chat.input);
    }
    if (chat?.report && !report) {
      setReport(chat.report);
    } else if (
      chat &&
      !chat.report &&
      !report &&
      !isCreatingReport &&
      !newReport
    ) {
      setIsCreatingReport(true);
      createReport();
    }
  }, [chat, report, isCreatingReport, newReport, createReport]);

  useEffect(() => {
    if (newReport) {
      setReport(newReport);
      setIsCreatingReport(false);
    }
  }, [newReport]);

  const isReportOutdated =
    report?.createdAt && chat?.finishedAt
      ? new Date(report.createdAt).getTime() <
        new Date(chat.finishedAt).getTime()
      : false;

  const handleRegenerateReport = () => {
    setIsCreatingReport(true);
    createReport();
  };

  return (
    <div className="min-h-screen bg-background">
      <div className="container mx-auto px-8 py-6">
        {/* Header */}
        <div className="sticky top-0 z-10 bg-background pb-4 border-b">
          <Button
            variant="ghost"
            onClick={() => navigate(`/c/${chatId}`)}
            className="absolute top-4 left-4"
          >
            <ArrowLeft className="h-4 w-4 mr-2" />
            Back to Chat
          </Button>

          <div className="flex items-center justify-between pt-16">
            <div>
              <h1 className="text-2xl font-semibold text-gray-900">
                {`${chatTitle} Report`}
              </h1>
              {report && (
                <p className="text-sm text-muted-foreground mt-1">
                  Generated on {new Date(report.createdAt).toLocaleString()}
                </p>
              )}
            </div>
            {report && (
              <div className="flex gap-3">
                <Button
                  size="lg"
                  variant={isReportOutdated ? "default" : "outline"}
                  className="gap-2 h-11"
                  onClick={handleRegenerateReport}
                  disabled={isCreatingReport}
                >
                  {isCreatingReport ? (
                    <>
                      <div className="h-4 w-4 animate-spin rounded-full border-2 border-current border-t-transparent mr-2" />
                      Regenerating...
                    </>
                  ) : (
                    <>
                      <RefreshCw className="h-5 w-5" />
                      Regenerate
                    </>
                  )}
                </Button>
                <Button
                  size="lg"
                  className="bg-gray-900 hover:bg-gray-800 text-white shadow-md hover:shadow-lg
                         transition-all duration-200 gap-2 h-11"
                  onClick={() => exportChatReport(chatId || "")}
                  disabled={isExporting}
                >
                  {isExporting ? (
                    <>
                      <div className="h-4 w-4 animate-spin rounded-full border-2 border-current border-t-transparent mr-2" />
                      Exporting...
                    </>
                  ) : (
                    <>
                      <FileDown className="h-5 w-5" />
                      Download PDF
                    </>
                  )}
                </Button>
              </div>
            )}
          </div>
        </div>

        {/* Report Content */}
        <div className="mt-6">
          {report ? (
            <>
              {isReportOutdated && (
                <Alert className="mb-6">
                  <AlertDescription>
                    This report is outdated. The chat has been updated since
                    this report was generated. Click "Regenerate" to create a
                    new report with the latest changes.
                  </AlertDescription>
                </Alert>
              )}
              <div className="rounded-lg border bg-card">
                <ScrollArea className="h-[calc(100vh-12rem)]">
                  <div className="p-6">
                    <MarkdownWithCitations
                      content={report.text}
                      className={cn(
                        markdownClasses["markdown"],
                        "prose prose-sm max-w-none dark:prose-invert prose-headings:scroll-m-20 prose-headings:font-semibold",
                        "prose-p:leading-7 prose-p:mb-4",
                        "prose-blockquote:border-l-2 prose-blockquote:border-border prose-blockquote:pl-6 prose-blockquote:italic",
                        "prose-code:rounded-md prose-code:bg-muted prose-code:p-1",
                        "prose-pre:rounded-lg prose-pre:bg-muted prose-pre:p-4",
                        "prose-img:rounded-lg",
                        "prose-table:w-full prose-thead:bg-muted prose-tr:border-b prose-th:p-3 prose-td:p-3",
                        "prose-ul:my-6 prose-ul:list-disc prose-ol:my-6 prose-ol:list-decimal",
                        "prose-li:my-2",
                      )}
                    />
                  </div>
                </ScrollArea>
              </div>
            </>
          ) : (
            <div className="h-[calc(100vh-12rem)] w-full flex items-center justify-center">
              <div className="flex flex-col items-center gap-4">
                <div className="h-8 w-8 animate-spin rounded-full border-4 border-primary border-t-transparent" />
                <p className="text-sm text-muted-foreground">
                  {isCreatingReport
                    ? "Generating report..."
                    : "Loading report..."}
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
