import { Button } from "@/components/ui/button";
import { Textarea } from "@/components/ui/textarea";
import { useLocalStorage } from "@mantine/hooks";
import { SendHorizontal, Square, Wand2 } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import CenterLoader from "../../shared/components/loader/CenterLoader";
import { ChatUpdateType } from "../../shared/enums/ChatUpdateType";
import { LocalStorageKeys } from "../../shared/enums/LocalStorageKeys";
import { UserRole } from "../../shared/enums/UserRole";
import { useChatUpdates } from "../../shared/hooks/useChatUpdates";
import { useHasRole } from "../../shared/hooks/useHasRole";
import { Chat } from "../../shared/models/Chat";
import { ChatReport } from "../../shared/models/ChatReport";
import { StepProcessor } from "../../shared/utils/StepProcessor";
import { useExportChat } from "./api/useExportChat";
import { useGetChat } from "./api/useGetChat";
import { useGetMessages } from "./api/useGetMessages";
import { useGetStep } from "./api/useGetStep";
import { useSendUserInputV2 } from "./api/useSendUserInputV2";
import { useSuggestQuestions } from "./api/useSuggestQuestions";
import MessageList from "./components/MessageList";
import { PromptImproverModal } from "./components/PromptImproverModal";
import { SuggestedQuestions } from "./components/SuggestedQuestions";
import { MessageDataType } from "./enums/MessageDataType";
import { ModelOutputStep } from "./models/chat-steps/ModelOutputStep";
import { ChatMessage } from "./models/ChatMessage";
import { ModelOutputMessageData } from "./models/message-data/ModelOutputMessageData";
import { MessageProcessor } from "./models/MessageDataProcessor";
import { ChatProcessingStep } from "./models/ProcessingStep";
import { Citation } from "../sources/models/Citation";
import { useStopChat } from "./api/useStopChat";

const wereNewsUsed = (messages: ChatMessage[]): boolean => {
  if (!messages) return false;
  return messages.some((message) => {
    if (!message || !message.data) return false;
    if (message.data.type === MessageDataType.ModelOutputMessageData) {
      const functionCalls = (message.data as ModelOutputMessageData)
        .functionCalls;
      return functionCalls?.some((call) => call.name === "get_news") || false;
    }
    return false;
  });
};

const wereInternetUsed = (messages: ChatMessage[]): boolean => {
  if (!messages) return false;
  return messages.some((message) => {
    if (!message || !message.data) return false;
    if (message.data.type === MessageDataType.ModelOutputMessageData) {
      const functionCalls = (message.data as ModelOutputMessageData)
        .functionCalls;
      return (
        functionCalls?.some((call) => call.name === "search_internet") || false
      );
    }
    return false;
  });
};

interface ExistingChatProps {
  chatId: string;
  userId?: string;
  autoScroll?: boolean;
  onCitationClick?: (citation: Citation) => void;
  onSourcesClick?: (citations: Citation[]) => void;
}

export default function ExistingChat({
  chatId,
  userId,
  autoScroll = true,
  onCitationClick,
  onSourcesClick,
}: ExistingChatProps) {
  const chatDisabled = useHasRole(UserRole.ChatDisabled);
  const { chat, getChat } = useGetChat(chatId, userId);
  const [inProgress, setInProgress] = useState(chat?.inProgress || false);
  const [steps, setSteps] = useState<ChatProcessingStep[]>([]);
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const { messages: updatedMessages, getMessages } = useGetMessages(chatId);
  const { step: updatedStep, getStep } = useGetStep(chatId);
  const [, setHasError] = useState(false);
  const [download, , clearDownload] = useLocalStorage({
    key: LocalStorageKeys.DownloadChatOnFinish,
  });
  const { exportChat } = useExportChat();
  const { newSuggestedQuestions, suggestQuestions } =
    useSuggestQuestions(chatId);
  const [suggestedQuestions, setSuggestedQuestions] = useState<string[]>([]);
  const [, setActiveTab] = useState<string>("chat");
  const [, setReport] = useState<ChatReport | undefined>(chat?.report);
  const [, setUseNewsDisabled] = useState(false);
  const [, setUseInternetDisabled] = useState(false);
  const lastMessageRef = useRef<ChatMessage | null>(null);
  const bottomRef = useRef<HTMLDivElement>(null);
  const [input, setInput] = useState("");
  const [showPromptImprover, setShowPromptImprover] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [isAtBottom, setIsAtBottom] = useState(true);
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const { stopChat } = useStopChat(chatId);

  const setChatProps = function (chat?: Chat) {
    setInProgress(chat?.inProgress || false);
    setSteps(chat?.steps || []);
    setMessages(chat?.messages?.filter((msg) => msg !== undefined) || []);
    setHasError(chat?.steps?.some((step) => step?.failed) || false);
    if (chat?.finishedAt && download === chatId) {
      clearDownload();
    }
  };

  useEffect(() => {
    // Clear all the states before fetching the chat
    setChatProps();
    setSuggestedQuestions([]);
    getChat();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatId]);

  useEffect(() => {
    if (!chat) return;
    setChatProps(chat);

    setReport(chat.report);
    setActiveTab("chat");

    // Scroll to bottom instantly when chat is first loaded
    if (chat.messages && chat.messages.length > 0) {
      scrollToBottom(false);
    }

    if (!chat.finishedAt) return;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chat]);

  useEffect(() => {
    if (!updatedMessages) return;
    setMessages(updatedMessages.filter((msg) => msg !== undefined));
    // Scroll to bottom when messages are first loaded
    if (isInitialLoad) {
      scrollToBottom();
      setIsInitialLoad(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedMessages, isInitialLoad]);

  useEffect(() => {
    if (!updatedStep) return;
    setSteps((prevSteps) =>
      StepProcessor.ProccessUpdatesStep(prevSteps, updatedStep),
    );
    setHasError(updatedStep.failed);
  }, [updatedStep]);

  useEffect(() => {
    if (!messages || !Array.isArray(messages) || messages.length === 0) return;

    const lastMessage = messages[messages.length - 1];
    if (!lastMessage || !lastMessage.data) return;

    // Skip if we've already processed this message
    if (lastMessageRef.current?.id === lastMessage.id) return;

    // Only proceed if we have a valid message with data
    if (lastMessage.data.type === MessageDataType.ModelOutputMessageData) {
      console.log("Found model output message, suggesting questions");
      lastMessageRef.current = lastMessage;
      suggestQuestions();
    }
  }, [messages, suggestQuestions]);

  useEffect(() => {
    if (newSuggestedQuestions?.suggestedQuestions) {
      setSuggestedQuestions(newSuggestedQuestions.suggestedQuestions);
    }
  }, [newSuggestedQuestions]);

  useEffect(() => {
    if (!messages || !Array.isArray(messages)) {
      setUseNewsDisabled(false);
      setUseInternetDisabled(false);
      return;
    }
    setUseNewsDisabled(wereNewsUsed(messages));
    setUseInternetDisabled(wereInternetUsed(messages));
  }, [messages]);

  useEffect(() => {
    const handleScroll = () => {
      if (!scrollContainerRef.current) return;
      const { scrollTop, scrollHeight, clientHeight } =
        scrollContainerRef.current;
      const isBottom = Math.abs(scrollHeight - scrollTop - clientHeight) < 10;
      setIsAtBottom(isBottom);
    };

    const scrollElement = scrollContainerRef.current;
    scrollElement?.addEventListener("scroll", handleScroll);
    return () => scrollElement?.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    if (!chat) return;

    if (!inProgress && !chat.finishedAt && download === chatId) {
      exportChat(chatId);
      clearDownload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inProgress]);

  useEffect(() => {
    // Only scroll when messages are being streamed, user is at bottom, and autoScroll is true
    if (inProgress && isAtBottom && autoScroll) {
      scrollToBottom();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages, inProgress, isAtBottom, autoScroll]);

  const scrollToBottom = (smooth = true) => {
    if (!autoScroll) return;

    setTimeout(() => {
      bottomRef.current?.scrollIntoView({
        behavior: smooth ? "smooth" : "instant",
      });
    }, 100); // Small delay to ensure content is rendered
  };

  const processUserInput = async (input: string) => {
    if (!input.trim() || chatDisabled) {
      return;
    }

    setInput(""); // Clear input immediately after validation
    setSuggestedQuestions([]);
    lastMessageRef.current = null;
    await sendUserInputV2(input);
    // Only scroll when autoScroll is true
    if (autoScroll) {
      scrollToBottom();
    }
  };

  const { sendUserInputV2 } = useSendUserInputV2(chatId);

  useChatUpdates(chatId, async (type, data) => {
    switch (type) {
      case ChatUpdateType.ChatStarted:
        setInProgress(true);
        break;
      case ChatUpdateType.ChatCompleted:
        setInProgress(false);
        break;

      case ChatUpdateType.ChatStepUpdated:
        await getStep(data.id);
        break;
      case ChatUpdateType.StepContentUpdated: {
        const stepId = data.id;
        // Get step from steps by id
        const updatedStep = steps.find(
          (step) => step.id === stepId,
        ) as ModelOutputStep;
        if (!updatedStep) return;
        updatedStep.completionPercentage = 50;
        updatedStep.output = (updatedStep?.output || "") + data.content;
        setSteps((prevSteps) =>
          StepProcessor.ProccessUpdatesStep(prevSteps, updatedStep),
        );
        break;
      }

      case ChatUpdateType.MessageUpdated:
        await getMessages();
        break;
      case ChatUpdateType.MessageContentUpdated: {
        const messageId = data.id;
        const content = data.content;
        setMessages((prev) =>
          MessageProcessor.ProcessContentUpdate(prev, messageId, content),
        );
        break;
      }
    }
  });

  return (
    <div className="flex flex-col h-full">
      <div className="flex-1 relative isolate">
        <div
          className="absolute inset-0 overflow-auto"
          ref={scrollContainerRef}
        >
          <div className="space-y-4 px-2">
            {!chat && <CenterLoader />}
            {chat && (
              <>
                {/* Messages */}
                <div className="space-y-4">
                  {messages.length > 0 && (
                    <>
                      <MessageList
                        messages={messages}
                        chat={chat}
                        onCitationClick={onCitationClick}
                        onSourcesClick={onSourcesClick}
                        autoScroll={autoScroll}
                      />
                      <div ref={bottomRef} className="h-4" />
                    </>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      <div className="flex-none bg-background border-t z-10">
        <div className="container py-4">
          {messages &&
            messages.length > 0 &&
            messages[messages.length - 1]?.data?.type ===
              MessageDataType.ModelOutputMessageData &&
            suggestedQuestions.length > 0 &&
            !inProgress && (
              <SuggestedQuestions
                suggestedQuestions={suggestedQuestions}
                processInput={processUserInput}
              />
            )}
          <div className="flex items-center gap-2">
            <Textarea
              placeholder="Type your message..."
              value={input}
              onChange={(e) => setInput(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter" && !e.shiftKey) {
                  e.preventDefault();
                  processUserInput(input);
                }
              }}
              disabled={chatDisabled || inProgress}
              className="flex-1 min-h-[44px] resize-none py-2"
              rows={1}
            />
            <Button
              variant="outline"
              onClick={() => input.trim() && setShowPromptImprover(true)}
              className="gap-2 px-3 hover:bg-gray-50 border-gray-200"
              disabled={!input.trim() || chatDisabled || inProgress}
            >
              <Wand2 className="h-4 w-4 text-gray-600" />
              <span className="text-sm text-gray-600">Improve</span>
            </Button>
            {!inProgress && (
              <Button
                onClick={() => processUserInput(input)}
                disabled={!input.trim() || chatDisabled}
              >
                <SendHorizontal className="h-4 w-4" />
              </Button>
            )}
            {inProgress && (
              <Button onClick={stopChat}>
                <Square className="h-4 w-4" />
              </Button>
            )}
          </div>
        </div>
      </div>

      <PromptImproverModal
        isOpen={showPromptImprover}
        onClose={() => setShowPromptImprover(false)}
        originalPrompt={input}
        onAccept={(improvedPrompt) => {
          setInput(improvedPrompt);
          setShowPromptImprover(false);
        }}
      />
    </div>
  );
}
