import { useApiPrivateRequest } from "../../../shared/hooks/useApi";
import { ChatUserContent } from "../../existing-chat/models/ChatUserContent";

export const useGetOneChatUserContent = () => {
  const { data: userContent, call } = useApiPrivateRequest<ChatUserContent>(
    (response) => new ChatUserContent(response),
  );
  const getUserContent = async (chatId: string, contentId: string) => {
    await call(`/chat/${chatId}/userContent/${contentId}`, "GET");
  };

  return { userContent, getUserContent };
};
