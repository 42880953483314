import ChatContext from "@/shared/models/ChatContext";
import { useApiPrivateRequest } from "../../../shared/hooks/useApi";
import { ChatShort } from "../../../shared/models/Chat";
import {
  EventName,
  EventParameter,
  EventTitle,
  sendEvent,
} from "../../../shared/utils/UsageAnalytics";

export const useCreatePlaybookChat = () => {
  const { data: chat, call } = useApiPrivateRequest<ChatShort>(
    (response) => new ChatShort(response),
  );
  const createChat = async (
    playbookId: string,
    isDefaultPlaybook: boolean,
    playbookTitle: string,
    sendPdf: boolean,
    context: ChatContext,
  ) => {
    const data = context.extractChatCreateData();

    const formatData = new FormData();
    formatData.append("PlaybookId", playbookId);
    formatData.append("IsDefaultPlaybook", isDefaultPlaybook.toString());
    formatData.append("PlaybookTitle", playbookTitle);
    data.companyIds.forEach((id) =>
      formatData.append("CompanyIds", id.toString()),
    );
    data.filingIds.forEach((id) => formatData.append("FilingIds", id));
    data.transcriptIds.forEach((id) => formatData.append("TranscriptIds", id));
    data.urls.forEach((url) => formatData.append("Urls", url));
    data.files.forEach((file) => formatData.append("Files", file));
    formatData.append("UseNews", data.includeNews.toString());
    formatData.append("UseInternet", data.includeWeb.toString());

    await call("/chat/playbook", "POST", { sendPdf }, formatData);

    sendEvent(EventName.ChatCreated, {
      [EventParameter.ChatMode]: EventTitle.ChatModeCompanyDocsV2,
    });
  };

  return { chat, createChat };
};
