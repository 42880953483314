import { useState, useRef, useEffect } from "react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import {
  X,
  Upload,
  Link as LinkIcon,
  File as FileText,
  ExternalLink as Link,
} from "lucide-react";
import { cn } from "@/lib/utils";

interface UploadItem {
  id: number;
  name: string;
  type: "file" | "url";
  size?: string;
  url?: string;
  status: "uploading" | "complete" | "error";
  error?: string;
  file?: File;
}

interface UploadOverlayProps {
  isOpen: boolean;
  onClose: () => void;
  selectedItems: UploadItem[];
  onItemAdd: (item: UploadItem) => void;
  onItemRemove: (itemId: number) => void;
}

export function UploadOverlay({
  isOpen,
  onClose,
  selectedItems,
  onItemAdd,
  onItemRemove,
}: UploadOverlayProps) {
  const [url, setUrl] = useState("");
  const [isValidUrl, setIsValidUrl] = useState(true);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isDragging, setIsDragging] = useState(false);
  const dragRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const div = dragRef.current;
    if (!div) return;

    const handleDragEnter = (e: DragEvent) => {
      e.preventDefault();
      e.stopPropagation();
      if (e.dataTransfer?.types.includes("Files")) {
        setIsDragging(true);
      }
    };

    const handleDragLeave = (e: DragEvent) => {
      e.preventDefault();
      e.stopPropagation();
      const rect = div.getBoundingClientRect();
      if (
        e.clientX <= rect.left ||
        e.clientX >= rect.right ||
        e.clientY <= rect.top ||
        e.clientY >= rect.bottom
      ) {
        setIsDragging(false);
      }
    };

    const handleDragOver = (e: DragEvent) => {
      e.preventDefault();
      e.stopPropagation();
      if (e.dataTransfer?.types.includes("Files")) {
        setIsDragging(true);
      } else {
        setIsDragging(false);
      }
    };

    const handleDrop = (e: DragEvent) => {
      e.preventDefault();
      e.stopPropagation();
      setIsDragging(false);

      const files = Array.from(e.dataTransfer?.files || []);
      if (files.length === 0) return;

      files.forEach((file) => {
        const size =
          file.size < 1024 * 1024
            ? `${(file.size / 1024).toFixed(1)} KB`
            : `${(file.size / (1024 * 1024)).toFixed(1)} MB`;

        const itemId = Date.now() + Math.random();
        const newItem = {
          id: itemId,
          name: file.name,
          type: "file" as const,
          size,
          status: "complete" as const,
          file: file,
        };

        onItemAdd(newItem);
      });
    };

    div.addEventListener("dragenter", handleDragEnter);
    div.addEventListener("dragleave", handleDragLeave);
    div.addEventListener("dragover", handleDragOver);
    div.addEventListener("drop", handleDrop);

    return () => {
      div.removeEventListener("dragenter", handleDragEnter);
      div.removeEventListener("dragleave", handleDragLeave);
      div.removeEventListener("dragover", handleDragOver);
      div.removeEventListener("drop", handleDrop);
    };
  }, [onItemAdd]);

  useEffect(() => {
    const handleWindowDragLeave = (e: DragEvent) => {
      if (!e.clientX && !e.clientY) {
        setIsDragging(false);
      }
    };

    window.addEventListener("dragleave", handleWindowDragLeave);
    return () => {
      window.removeEventListener("dragleave", handleWindowDragLeave);
    };
  }, []);

  const validateUrl = (url: string) => {
    try {
      new URL(url);
      return true;
    } catch {
      return false;
    }
  };

  const handleUrlSubmit = () => {
    if (!url.trim()) return;

    const isValid = validateUrl(url);
    setIsValidUrl(isValid);

    if (isValid) {
      const newItem = {
        id: Date.now(),
        name: url,
        type: "url" as const,
        url: url,
        status: "complete" as const,
      };
      onItemAdd(newItem);
      setUrl("");
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (!files) return;

    Array.from(files).forEach((file) => {
      // Convert file size to readable format
      const size =
        file.size < 1024 * 1024
          ? `${(file.size / 1024).toFixed(1)} KB`
          : `${(file.size / (1024 * 1024)).toFixed(1)} MB`;

      const itemId = Date.now() + Math.random();
      const newItem = {
        id: itemId,
        name: file.name,
        type: "file" as const,
        size,
        status: "complete" as const,
        file: file, // Store the actual File object
      };

      onItemAdd(newItem);
    });

    // Reset file input
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
      <div className="bg-white rounded-xl shadow-2xl w-[800px] h-[80vh] flex flex-col">
        {/* Header */}
        <div className="p-6 border-b">
          <div className="flex items-center justify-between mb-4">
            <h3 className="text-xl font-semibold text-gray-900">
              Add File or URL
            </h3>
            <Button
              variant="ghost"
              size="icon"
              onClick={onClose}
              className="hover:bg-gray-100"
            >
              <X className="h-4 w-4" />
            </Button>
          </div>
        </div>

        {/* Content */}
        <div className="flex-1 p-6 overflow-y-auto min-h-0">
          {/* File Upload Section */}
          <div className="mb-8">
            <h4 className="text-lg font-medium text-gray-900 mb-4">
              Upload Files
            </h4>
            <div
              ref={dragRef}
              className={cn(
                "border-2 border-dashed rounded-lg p-12 text-center cursor-pointer min-h-[350px] flex flex-col items-center justify-center",
                isDragging
                  ? "border-purple-500 bg-purple-50"
                  : "border-gray-200",
                !isDragging && "hover:border-gray-300",
              )}
            >
              <input
                type="file"
                onChange={handleFileChange}
                className="hidden"
                ref={fileInputRef}
                multiple
                id="file-upload"
                accept=".pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.csv,.json,.xml,.md,.txt"
              />
              <label
                htmlFor="file-upload"
                className="cursor-pointer flex flex-col items-center"
              >
                <Upload className="h-10 w-10 text-gray-400 mb-4" />
                <span className="text-sm text-gray-600">
                  Click to select files or drag and drop
                </span>
              </label>
            </div>

            {/* Selected Files List */}
            {selectedItems.filter((item) => item.type === "file").length >
              0 && (
              <div className="mt-4 space-y-2">
                {selectedItems
                  .filter((item) => item.type === "file")
                  .map((file) => (
                    <div
                      key={file.id}
                      className="flex items-center justify-between bg-purple-50 text-purple-700 px-4 py-2 rounded-lg"
                    >
                      <div className="flex items-center gap-2">
                        <FileText className="h-4 w-4" />
                        <span>{file.name}</span>
                        {file.size && (
                          <span className="text-sm text-purple-500">
                            ({file.size})
                          </span>
                        )}
                      </div>
                      <button
                        onClick={() => onItemRemove(file.id)}
                        className="text-purple-500 hover:text-purple-700"
                      >
                        <X className="h-4 w-4" />
                      </button>
                    </div>
                  ))}
              </div>
            )}
          </div>

          {/* URL Input Section */}
          <div>
            <h4 className="text-lg font-medium text-gray-900 mb-4">Add URLs</h4>
            <div className="space-y-4">
              <div className="flex gap-2">
                <Input
                  placeholder="Paste URL here..."
                  value={url}
                  onChange={(e) => setUrl(e.target.value)}
                  className={cn(
                    "flex-1",
                    !isValidUrl && "border-red-500 focus-visible:ring-red-500",
                  )}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      handleUrlSubmit();
                    }
                  }}
                />
                <Button onClick={handleUrlSubmit} variant="outline">
                  Add
                </Button>
              </div>

              {/* URL List */}
              {selectedItems.filter((item) => item.type === "url").length >
                0 && (
                <div className="space-y-2">
                  {selectedItems
                    .filter((item) => item.type === "url")
                    .map((item) => (
                      <div
                        key={item.id}
                        className="flex items-center justify-between bg-purple-50 text-purple-700 px-4 py-2 rounded-lg"
                      >
                        <div className="flex items-center gap-2">
                          <LinkIcon className="h-4 w-4" />
                          <a
                            href={item.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="hover:text-purple-900 flex items-center gap-1"
                          >
                            <span className="truncate">{item.url}</span>
                            <Link className="h-3 w-3" />
                          </a>
                        </div>
                        <button
                          onClick={() => onItemRemove(item.id)}
                          className="text-purple-500 hover:text-purple-700 ml-2"
                        >
                          <X className="h-4 w-4" />
                        </button>
                      </div>
                    ))}
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Footer */}
        <div className="p-6 border-t bg-gray-50 flex items-center justify-between">
          <div className="text-sm text-gray-600">
            {selectedItems.length} items added
          </div>
          <Button onClick={onClose}>Done</Button>
        </div>
      </div>
    </div>
  );
}
