import { useGetUserContentChunk } from "../../../sources/api/useGetUserContentChunk";
import { CitationIndex } from "../../../sources/models/Citation";
import { useEffect, useMemo } from "react";
import { ScrollArea } from "@/components/ui/scroll-area";
import { parseIntOrUndefined } from "../../../../shared/utils/Number";
import CenterLoader from "../../../../shared/components/loader/CenterLoader";
import SidebarUserContent from "./SidebarUserContent";

interface SidebarUserContentCitationProps {
  citation: CitationIndex;
  chatId: string;
}

export function SidebarUserContentCitation({
  citation,
  chatId,
}: SidebarUserContentCitationProps) {
  const { source, getUserContentChunkText, isLoading } =
    useGetUserContentChunk();

  const memoizedCitation = useMemo(() => citation, [citation]);

  useEffect(() => {
    getUserContentChunkText(
      memoizedCitation.sourceType,
      memoizedCitation.documentId,
      chatId,
      parseIntOrUndefined(memoizedCitation.chunkId),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memoizedCitation]);

  return (
    <>
      {isLoading && <CenterLoader />}
      <div className="flex">
        {source && (
          <SidebarUserContent
            chatId={chatId}
            contentId={source.id}
            chunkText={source.chunkText}
          />
        )}
      </div>
      <div className="h-full">
        {source && (
          <ScrollArea className="mt-5" scrollHideDelay={0}>
            <p
              className="text-sm leading-relaxed"
              style={{ overflowWrap: "anywhere" }}
            >
              {source.chunkText}
            </p>
          </ScrollArea>
        )}
      </div>
    </>
  );
}
