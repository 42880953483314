import ChatContext from "@/shared/models/ChatContext";
import { useApiPrivateRequest } from "../../../shared/hooks/useApi";
import { ChatShort } from "../../../shared/models/Chat";
import {
  EventName,
  EventParameter,
  EventTitle,
  sendEvent,
} from "../../../shared/utils/UsageAnalytics";

export const useCreateCompanyDocsV2Chat = () => {
  const { call, isLoading } = useApiPrivateRequest<ChatShort>((response) => {
    console.log("Mapper received response:", response);
    if (!response) {
      throw new Error("Response is null or undefined");
    }
    const chatShort = new ChatShort(response);
    console.log("Created ChatShort:", chatShort);
    return chatShort;
  });

  const createChat = async (input: string, context: ChatContext) => {
    const data = context.extractChatCreateData();

    const formatData = new FormData();
    formatData.append("Input", input.trim());
    data.companyIds.forEach((id) =>
      formatData.append("CompanyIds", id.toString()),
    );
    data.filingIds.forEach((id) => formatData.append("FilingIds", id));
    data.transcriptIds.forEach((id) => formatData.append("TranscriptIds", id));
    data.urls.forEach((url) => formatData.append("Urls", url));
    data.files.forEach((file) => formatData.append("Files", file));
    formatData.append("UseNews", data.includeNews.toString());
    formatData.append("UseInternet", data.includeWeb.toString());

    const response = await call(
      "/chat/companyDocsV2",
      "POST",
      undefined,
      formatData,
    );
    console.log("API Response:", response);

    if (response) {
      sendEvent(EventName.ChatCreated, {
        [EventParameter.Label]: EventTitle.ChatModeCompanyDocsV2,
        [EventParameter.CompanyCount]: data.companyIds.length.toString(),
        [EventParameter.DocumentCount]: data.filingIds.length.toString(),
        [EventParameter.TranscriptCount]: data.transcriptIds.length.toString(),
        [EventParameter.FileCount]: data.files.length.toString(),
        [EventParameter.UrlCount]: data.urls.length.toString(),
        [EventParameter.UseNews]: data.includeNews.toString(),
        [EventParameter.UseInternet]: data.includeWeb.toString(),
      });
    }

    return response;
  };

  return { createChat, isLoading };
};
