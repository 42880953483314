import { useState, useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { Card, CardContent } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import {
  FileText,
  Building2,
  Newspaper,
  Globe,
  Plus,
  Edit2,
  Save,
  PlayCircle,
  ChevronDown,
  ChevronUp,
  Share,
  Copy,
} from "lucide-react";
import { useUpdatePlaybook } from "../features/playbook/api/useUpdatePlaybook";
import { useGetPlaybook } from "../features/playbook/api/useGetPlaybook";
import { useGetDefaultPlaybook } from "../features/playbook/api/useGetDefaultPlaybook";
import { useCreatePlaybookChat } from "../features/playbook/api/useCreatePlaybookChat";
import { useSharePlaybook } from "../features/playbook/api/useSharePlaybook";
import { PlaybookStepShort } from "../features/playbook/model/Playbook";
import { AddContextModal } from "../features/chat-management/components/AddContextModal";
import { SortableStep } from "../features/playbook/components/SortableStep";
import { PlaybookShareModal } from "@/features/playbook/components/PlaybookShareModal";
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogFooter,
  AlertDialogCancel,
  AlertDialogAction,
} from "@/components/ui/alert-dialog";
import { useDuplicatePlaybook } from "@/features/playbook/api/useDuplicatePlaybook";
import ChatContext from "@/shared/models/ChatContext";

export default function PlaybookEdit() {
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();
  const [context, setContext] = useState<any>(null);
  const [editMode, setEditMode] = useState(id === undefined || id === "new");
  const [showShareModal, setShowShareModal] = useState(false);
  const [isContextCollapsed, setIsContextCollapsed] = useState(false);
  const [showContext, setShowContext] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [isDefault, setIsDefault] = useState(false);
  const [showDuplicateAlert, setShowDuplicateAlert] = useState(false);

  const { playbook, getPlaybook } = useGetPlaybook();
  const {
    sharePlaybook,
    isLoading: isSharing,
    isFailed: isShareFailed,
  } = useSharePlaybook();
  const { defaultPlaybook, getDefaultPlaybook } = useGetDefaultPlaybook();
  const { updatePlaybook } = useUpdatePlaybook();
  const { createChat, chat } = useCreatePlaybookChat();
  const [steps, setSteps] = useState<PlaybookStepShort[]>([]);
  const { duplicatePlaybook, duplicatedPlaybook, isDuplicating } =
    useDuplicatePlaybook();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const contextParam = searchParams.get("context");
    const isDefaultParam = searchParams.get("isDefault");
    setIsDefault(isDefaultParam === "true");

    if (contextParam) {
      setContext(JSON.parse(decodeURIComponent(contextParam)));
    }

    if (id && id !== "new") {
      if (isDefaultParam === "true") {
        getDefaultPlaybook(id);
      } else {
        getPlaybook(id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, location.search]);

  useEffect(() => {
    if (playbook) {
      console.log("Setting steps from playbook:", playbook.steps);
      setSteps(playbook.steps || []);
      setTitle(playbook.title || "");
      setDescription(playbook.description || "");
    }
  }, [playbook]);

  useEffect(() => {
    if (defaultPlaybook) {
      console.log(
        "Setting steps from default playbook:",
        defaultPlaybook.steps,
      );
      setSteps(defaultPlaybook.steps || []);
      setTitle(defaultPlaybook.title || "");
      setDescription(defaultPlaybook.description || "");
    }
  }, [defaultPlaybook]);

  useEffect(() => {
    if (!chat) return;
    navigate(`/c/${chat.id}`);
  }, [chat, navigate]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleAddStep = () => {
    setSteps([
      ...steps,
      {
        id: crypto.randomUUID(),
        title: `Step ${steps.length + 1}`,
        prompt: "",
        order: steps.length,
      },
    ]);
  };

  const handleDeleteStep = (stepId: string) => {
    const newSteps = steps.filter((step) => step.id !== stepId);
    setSteps(newSteps.map((step, i) => ({ ...step, order: i })));
  };

  const handleStepChange = (
    stepId: string,
    field: keyof PlaybookStepShort,
    value: string,
  ) => {
    console.log("Changing step:", { stepId, field, value });
    const newSteps = steps.map((step) =>
      step.id === stepId ? { ...step, [field]: value } : step,
    );
    console.log("New steps:", newSteps);
    setSteps(newSteps);
  };

  const handleSavePlaybook = async (currentStep?: PlaybookStepShort) => {
    if (
      !playbook ||
      !title.trim() ||
      steps.some((step) => !step.title.trim() || !step.prompt.trim())
    ) {
      return;
    }

    // If we have a current step, update it in the steps array
    const stepsToSave = currentStep
      ? steps.map((step) => (step.id === currentStep.id ? currentStep : step))
      : steps;

    const updatedSteps = stepsToSave.map((step, index) => ({
      ...step,
      order: index,
    }));
    console.log("Saving steps:", updatedSteps);

    await updatePlaybook(
      playbook.id,
      title,
      description,
      undefined,
      updatedSteps,
    );
    if (isDefault) {
      await getDefaultPlaybook(playbook.id);
    } else {
      await getPlaybook(playbook.id);
    }
    setEditMode(false);
  };

  const handleCancelEdit = () => {
    if (playbook) {
      setTitle(playbook.title);
      setDescription(playbook.description || "");
      setSteps(playbook.steps);
    }
    setEditMode(false);
  };

  const handleBack = () => {
    navigate("/playbooks");
  };

  const handleContextComplete = async (contextData: ChatContext) => {
    setShowContext(false);
    if (!playbook && !defaultPlaybook) return;

    await createChat(
      playbook ? playbook.id : defaultPlaybook!.id,
      isDefault,
      playbook ? playbook.title : defaultPlaybook!.title,
      false, // sendPdf
      contextData,
    );
  };

  const handleDragEnd = (event: any) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      setSteps((items) => {
        const oldIndex = items.findIndex((item) => item.id === active.id);
        const newIndex = items.findIndex((item) => item.id === over.id);

        const newItems = arrayMove(items, oldIndex, newIndex);
        return newItems.map((item, index) => ({
          ...item,
          order: index,
        }));
      });
    }
  };

  const handleDuplicate = async () => {
    if (!id) return;
    await duplicatePlaybook(id, isDefault);
    setShowDuplicateAlert(false);
  };

  useEffect(() => {
    if (!duplicatedPlaybook) return;
    navigate(`/playbooks/${duplicatedPlaybook.id}/edit`);
  }, [duplicatedPlaybook, navigate]);

  return (
    <div className="min-h-screen bg-background">
      <div className="container mx-auto p-6">
        <Button variant="ghost" onClick={handleBack} className="mb-4">
          ← Back to Playbooks
        </Button>

        <div className="flex items-center justify-between mb-4">
          <div>
            <h1 className="text-3xl font-bold mb-4">
              {id === "new" ? (
                "New Quantly Playbook"
              ) : editMode && !isDefault ? (
                <div className="space-y-1">
                  <label className="text-sm font-medium text-gray-700">
                    Title
                  </label>
                  <Input
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    placeholder="Enter playbook title"
                    className="text-lg font-semibold w-[1000px]"
                    autoFocus={false}
                  />
                </div>
              ) : (
                title
              )}
            </h1>
            {editMode ? (
              <div className="space-y-1">
                <label className="text-sm font-medium text-gray-700">
                  Description
                </label>
                <Textarea
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  placeholder="Enter playbook description"
                  className="mb-2 min-h-[100px] resize-none w-[1000px]"
                />
              </div>
            ) : (
              <div className="space-y-1">
                <label className="text-sm font-medium text-gray-700">
                  Description
                </label>
                <div className="text-gray-600 mb-2 whitespace-pre-wrap w-[1000px]">
                  {description ? description : "No description"}
                </div>
              </div>
            )}
          </div>
          <div className="flex gap-2">
            {!editMode && !isDefault && (
              <>
                <Button
                  variant="outline"
                  onClick={() => setShowShareModal(true)}
                >
                  <Share className="h-4 w-4 mr-2" />
                  Share
                </Button>
                <Button variant="outline" onClick={() => setEditMode(true)}>
                  <Edit2 className="h-4 w-4 mr-2" />
                  Edit
                </Button>
                <Button
                  variant="outline"
                  onClick={() => setShowDuplicateAlert(true)}
                >
                  <Copy className="h-4 w-4 mr-2" />
                  Duplicate
                </Button>
              </>
            )}
            {isDefault && (
              <Button
                variant="outline"
                onClick={() => setShowDuplicateAlert(true)}
              >
                <Copy className="h-4 w-4 mr-2" />
                Duplicate
              </Button>
            )}
            {editMode && !isDefault && (
              <>
                <Button variant="outline" onClick={handleCancelEdit}>
                  Cancel
                </Button>
                <Button
                  onClick={() => handleSavePlaybook()}
                  className="bg-gray-900 hover:bg-gray-800"
                >
                  <Save className="h-4 w-4 mr-2" />
                  Save Playbook
                </Button>
              </>
            )}
          </div>
        </div>

        {/* Context Card */}
        {context && (
          <Card className="mb-4">
            <CardContent className="p-6">
              <div className="flex items-center justify-between mb-2">
                <h2 className="text-lg font-semibold">Context</h2>
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={() => setIsContextCollapsed(!isContextCollapsed)}
                >
                  {isContextCollapsed ? (
                    <ChevronDown className="h-4 w-4" />
                  ) : (
                    <ChevronUp className="h-4 w-4" />
                  )}
                </Button>
              </div>

              <div className={isContextCollapsed ? "hidden" : ""}>
                <div className="grid grid-cols-2 gap-3">
                  {context.companies && context.companies.length > 0 && (
                    <div>
                      <h3 className="text-sm font-medium text-gray-700 mb-2 flex items-center gap-2">
                        <Building2 className="h-4 w-4" />
                        Companies
                      </h3>
                      <div className="space-y-1">
                        {context.companies.map((company: any) => (
                          <div
                            key={company.id}
                            className="text-sm text-gray-600"
                          >
                            {company.name}
                          </div>
                        ))}
                      </div>
                    </div>
                  )}

                  {context.documents && context.documents.length > 0 && (
                    <div>
                      <h3 className="text-sm font-medium text-gray-700 mb-2 flex items-center gap-2">
                        <FileText className="h-4 w-4" />
                        Documents
                      </h3>
                      <div className="space-y-1">
                        {context.documents.map((doc: any) => (
                          <div key={doc.id} className="text-sm text-gray-600">
                            {doc.title}
                          </div>
                        ))}
                      </div>
                    </div>
                  )}

                  <div>
                    <h3 className="text-sm font-medium text-gray-700 mb-2 flex items-center gap-2">
                      <Newspaper className="h-4 w-4" />
                      News Search
                    </h3>
                    <div className="text-sm text-gray-600">
                      {context.includeNews ? "Enabled" : "Disabled"}
                    </div>
                  </div>

                  <div>
                    <h3 className="text-sm font-medium text-gray-700 mb-2 flex items-center gap-2">
                      <Globe className="h-4 w-4" />
                      Web Search
                    </h3>
                    <div className="text-sm text-gray-600">
                      {context.includeWeb ? "Enabled" : "Disabled"}
                    </div>
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>
        )}

        {/* Playbook Steps */}
        <div className="space-y-4 mb-8">
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
          >
            <SortableContext
              items={steps.map((s) => s.id)}
              strategy={verticalListSortingStrategy}
            >
              {(playbook || defaultPlaybook) &&
                steps.map((step, index) => (
                  <SortableStep
                    key={step.id}
                    playbookId={playbook ? playbook.id : defaultPlaybook!.id}
                    step={step}
                    index={index}
                    editMode={editMode && !isDefault}
                    onStepChange={handleStepChange}
                    onDeleteStep={handleDeleteStep}
                    onSave={handleSavePlaybook}
                    isReadOnly={isDefault}
                  />
                ))}
            </SortableContext>
          </DndContext>
        </div>

        {/* Add Step Button */}
        {editMode && !isDefault && (
          <Button
            variant="outline"
            onClick={handleAddStep}
            className="w-full py-6 border-dashed"
          >
            <Plus className="h-4 w-4 mr-2" />
            Add Step
          </Button>
        )}

        {/* Start Playbook Button */}
        {!editMode && steps.length > 0 && (
          <div className="mt-16 pb-24 flex justify-center">
            <Button
              size="lg"
              className="bg-gray-900 hover:bg-gray-800 text-white px-8 py-6 rounded-xl 
                       shadow-lg hover:shadow-xl transition-all duration-200 
                       flex items-center gap-3 h-auto border border-gray-800
                       hover:border-gray-700"
              onClick={() => setShowContext(true)}
            >
              <PlayCircle className="h-5 w-5" />
              <span className="text-base font-medium">Start Playbook</span>
            </Button>
          </div>
        )}
      </div>
      {showContext && (
        <AddContextModal
          open={showContext}
          onClose={() => setShowContext(false)}
          onComplete={handleContextComplete}
          isPlaybook={true}
        />
      )}
      {showShareModal && (
        <PlaybookShareModal
          open={showShareModal}
          onClose={() => setShowShareModal(false)}
          onShare={(email) => {
            sharePlaybook(playbook!.id, email);
          }}
          isSharing={isSharing}
          isFailed={isShareFailed}
        />
      )}

      <AlertDialog
        open={showDuplicateAlert}
        onOpenChange={setShowDuplicateAlert}
      >
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>
              Are you sure you want to duplicate this playbook?
            </AlertDialogTitle>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction
              onClick={handleDuplicate}
              disabled={isDuplicating}
            >
              {isDuplicating ? "Duplicating..." : "Duplicate"}
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
}
