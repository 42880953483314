import { useEffect, useState, useCallback, useRef } from "react";
import { useGetChats } from "./api/useGetChats";
import { useNavigate } from "react-router-dom";
import { Calendar, Building2, Search, X } from "lucide-react";
import ChatItemList from "./components/ChatItemList";
import { useEventListener } from "../../shared/hooks/useEventManagement";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { format } from "date-fns";
import { DateRange } from "react-day-picker";
import { Calendar as CalendarComponent } from "@/components/ui/calendar";
import { cn } from "@/lib/utils";
import { ChatShort } from "../../shared/models/Chat";
import { QCustomEvent } from "../../shared/hooks/useEventManagement";
import { useSearchCompanies } from "../../shared/hooks/useSearchCompanies";
import { CompanyInfo } from "../../shared/components/company-info/models/CompanyInfo";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";

interface ChatHistoryProps {
  userId?: string;
}

const ITEMS_PER_PAGE = 10;

export default function ChatManagement({ userId }: ChatHistoryProps) {
  const navigate = useNavigate();
  const { getChats } = useGetChats(userId);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCompany, setSelectedCompany] = useState<CompanyInfo | null>(
    null,
  );
  const [isCompanySearchOpen, setIsCompanySearchOpen] = useState(false);
  const [companySearchValue, setCompanySearchValue] = useState("");
  const {
    companies,
    searchCompanies,
    isLoading: isCompanySearchLoading,
    setCompanies,
  } = useSearchCompanies();
  const [dateRange, setDateRange] = useState<{
    from: Date | undefined;
    to: Date | undefined;
  }>({
    from: undefined,
    to: undefined,
  });

  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [allChats, setAllChats] = useState<ChatShort[]>([]);
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  useEffect(() => {
    if (!companySearchValue) {
      setCompanies(null);
      return;
    }

    const timer = setTimeout(() => {
      searchCompanies(companySearchValue);
    }, 500);

    return () => clearTimeout(timer);
  }, [companySearchValue, searchCompanies, setCompanies]);

  const observer = useRef<IntersectionObserver>();
  const lastChatElementRef = useCallback(
    (node: HTMLDivElement) => {
      if (isLoading || !hasMore) return;

      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPage((prevPage) => prevPage + 1);
        }
      });

      if (node) observer.current.observe(node);
    },
    [isLoading, hasMore],
  );

  // Reset state when filters change or component mounts
  useEffect(() => {
    setPage(1);
    setAllChats([]);
    setHasMore(true);
  }, [selectedCompany]);

  // Fetch chats when page changes
  useEffect(() => {
    const fetchChats = async () => {
      setIsLoading(true);
      try {
        const newChats = await getChats({
          page,
          pageSize: ITEMS_PER_PAGE,
          ...(selectedCompany ? { companyId: selectedCompany.id } : {}),
          ...(searchQuery ? { query: searchQuery } : {}),
          ...(dateRange.from ? { from: dateRange.from } : {}),
          ...(dateRange.to ? { to: dateRange.to } : {}),
        });

        setAllChats((prev) => (page === 1 ? newChats : [...prev, ...newChats]));
        setHasMore(newChats.length === ITEMS_PER_PAGE);
      } catch (error) {
        console.error("Error fetching chats:", error);
        setHasMore(false);
      } finally {
        setIsLoading(false);
        setIsInitialLoad(false);
      }
    };

    fetchChats();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, selectedCompany, searchQuery, dateRange, userId]);

  // Listen for refresh events
  useEventListener(QCustomEvent.RefreshChats, () => {
    setPage(1);
    setAllChats([]);
    setHasMore(true);
    setIsInitialLoad(true);
  });

  const handleChatClick = useCallback(
    (chat: ChatShort) => {
      navigate(`/c/${chat.id}`);
    },
    [navigate],
  );

  const handleDateRangeSelect = useCallback((range: DateRange | undefined) => {
    setDateRange({
      from: range?.from,
      to: range?.to,
    });
  }, []);

  const handleCompanySelect = useCallback((company: CompanyInfo) => {
    setSelectedCompany(company);
    setIsCompanySearchOpen(false);
    setCompanySearchValue("");
  }, []);

  if (isInitialLoad) {
    return (
      <div className="flex justify-center py-12">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
      </div>
    );
  }

  const onChatDelete = (chatId: string) => {
    // Remove chat from the list
    setAllChats((prevChats) => prevChats.filter((chat) => chat.id !== chatId));
  };

  return (
    <div className="flex flex-col gap-6">
      {/* Filters */}
      <div className="flex items-center gap-3">
        <Popover>
          <PopoverTrigger asChild>
            <Button
              variant="outline"
              className={cn(
                "justify-start text-left font-normal",
                !dateRange.from && !dateRange.to && "text-muted-foreground",
              )}
            >
              <Calendar className="mr-2 h-4 w-4" />
              {dateRange.from ? (
                dateRange.to ? (
                  <>
                    {format(dateRange.from, "LLL dd, y")} -{" "}
                    {format(dateRange.to, "LLL dd, y")}
                  </>
                ) : (
                  format(dateRange.from, "LLL dd, y")
                )
              ) : (
                "Pick a date range"
              )}
            </Button>
          </PopoverTrigger>
          <PopoverContent className="w-auto p-0" align="start">
            <CalendarComponent
              initialFocus
              mode="range"
              defaultMonth={dateRange.from}
              selected={dateRange}
              onSelect={handleDateRangeSelect}
              numberOfMonths={2}
            />
          </PopoverContent>
        </Popover>

        {/* Company Filter */}
        <div className="relative w-[300px]">
          <Popover
            open={isCompanySearchOpen}
            onOpenChange={setIsCompanySearchOpen}
          >
            <PopoverTrigger asChild>
              {selectedCompany ? (
                <Button
                  variant="outline"
                  role="combobox"
                  className="w-full justify-between"
                >
                  <div className="flex items-center gap-2">
                    <Avatar className="h-6 w-6">
                      <AvatarImage src={selectedCompany.logo} />
                      <AvatarFallback>
                        {selectedCompany.companyName[0]}
                      </AvatarFallback>
                    </Avatar>
                    <span>{selectedCompany.companyName}</span>
                  </div>
                  <Button
                    variant="ghost"
                    size="icon"
                    className="h-4 w-4 p-0 hover:bg-transparent"
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedCompany(null);
                    }}
                  >
                    <X className="h-3 w-3" />
                  </Button>
                </Button>
              ) : (
                <Button
                  variant="outline"
                  role="combobox"
                  aria-expanded={isCompanySearchOpen}
                  className="w-full justify-between"
                >
                  <div className="flex items-center gap-2">
                    <Building2 className="h-4 w-4 text-gray-400" />
                    <span className="text-muted-foreground">
                      Search company...
                    </span>
                  </div>
                </Button>
              )}
            </PopoverTrigger>
            <PopoverContent className="w-[300px] p-0" align="start">
              <Command>
                <CommandInput
                  placeholder="Search company..."
                  value={companySearchValue}
                  onValueChange={(value) => {
                    setCompanySearchValue(value);
                  }}
                />
                <CommandList>
                  {companySearchValue.trim() ? (
                    isCompanySearchLoading ? (
                      <div className="flex justify-center py-6">
                        <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-gray-900"></div>
                      </div>
                    ) : companies && companies.length > 0 ? (
                      <CommandGroup heading="Companies">
                        {companies.map((company) => (
                          <CommandItem
                            key={company.id}
                            value={company.companyName}
                            onSelect={() => handleCompanySelect(company)}
                          >
                            <div className="flex items-center gap-2">
                              <Avatar className="h-6 w-6">
                                <AvatarImage src={company.logo} />
                                <AvatarFallback>
                                  {company.companyName[0]}
                                </AvatarFallback>
                              </Avatar>
                              <span>{company.companyName}</span>
                              <span className="text-gray-500 text-sm">
                                ({company.tickerSymbol})
                              </span>
                            </div>
                          </CommandItem>
                        ))}
                      </CommandGroup>
                    ) : (
                      <CommandEmpty>No companies found</CommandEmpty>
                    )
                  ) : (
                    <CommandEmpty>Type to search companies...</CommandEmpty>
                  )}
                </CommandList>
              </Command>
            </PopoverContent>
          </Popover>
        </div>

        {/* Search Box */}
        <div className="relative flex-1">
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-4 w-4 text-gray-400" />
          <Input
            placeholder="Find research..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="pl-9"
          />
        </div>
      </div>

      {/* Chat List */}
      <ChatItemList
        chats={allChats}
        userId={userId}
        lastChatRef={lastChatElementRef}
        onChatClick={handleChatClick}
        onChatDelete={onChatDelete}
      />

      {/* Loading indicator */}
      {isLoading && (
        <div className="flex justify-center py-4">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
        </div>
      )}

      {/* No more chats indicator */}
      {!isLoading && !hasMore && allChats.length > 0 && (
        <div className="text-center py-4 text-gray-500">
          No more chats to load
        </div>
      )}

      {/* Empty state */}
      {!isLoading && allChats.length === 0 && (
        <div className="text-center py-12">
          <p className="text-gray-500">No chats found</p>
        </div>
      )}
    </div>
  );
}
